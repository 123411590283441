
.root {
  z-index: 999;
  border: solid 1px #ddd,
}

.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0,0,0,.06);
}

.root iframe {
  width: 100%;
  flex: 1;
  border: 0;
  position: relative;
}

.bar {
  text-align: end;
  position: absolute;
  z-index: 999;
  width: 100%;
}

.close {
  position: absolute;
  z-index: 999;
  bottom: 0;
  right: 0;
  margin: 10px;
}

.bar button {
  margin-right: 2px;
}